/* You can add global styles to this file, and also import other style files */
@import "node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "./styles/_normalize.scss";
@import "./styles/_variables.scss";

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@font-face {
  font-family: "Glyphicons Halflings";
  src: url($glyphicons-halflings-regular + ".eot");
  src:
    url($glyphicons-halflings-regular + ".eot?#iefix")
      format("embedded-opentype"),
    url($glyphicons-halflings-regular + ".woff2") format("woff2"),
    url($glyphicons-halflings-regular + ".woff") format("woff"),
    url($glyphicons-halflings-regular + ".ttf") format("truetype"),
    url($glyphicons-halflings-regular + ".svg#glyphicons_halflingsregular")
      format("svg");
}

@font-face {
  font-family: "icomoon";
  src: url($icomoon + ".eot");
  src:
    url($icomoon + ".eot?#iefix") format("embedded-opentype"),
    url($icomoon + ".woff") format("woff"),
    url($icomoon + ".ttf") format("truetype"),
    url($icomoon + ".svg#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

body {
  font-family: "Inter";
}

.container-body {
  margin-top: 20px;
  padding-bottom: 20px;
}

.clickable {
  cursor: pointer;
}

.not-clickable {
  cursor: default;
}

.imgHide {
  display: none;
}
.btn-teleHealth {
  background-color: #00c3ff !important;
  border-color: #00c3ff !important;
}

.modal-content {
  border: none;
}
.navbar {
  border: none;
}

.modal.fade .modal-dialog {
  transform: translate(0, 0) !important;
}

.mobile-only {
  display: none;
}
.desktop-only {
  display: block;
}
@media (max-width: $screen-sm) {
  .mobile-only {
    display: block;
  }
  .desktop-only {
    display: none;
  }
}

// CBHS boostrap fix
.col-sm-6,
.col-lg-4 {
  float: left;
}

.col-xs-6 {
  width: 50%;
}
@media (min-width: 992px) {
  .col-md-3 {
    width: 25%;
  }
}

.modal {
  --bs-modal-margin: 1.75rem;
  --bs-modal-box-shadow: var(--bs-box-shadow);
}
